import React from 'react';
import '../styles/About.css';
import meImage from '../assets/me2.jpg';
import emccLogo from '../assets/EMCC.png';
import medoucineLogo from '../assets/medoucine_label.png';

declare const Calendly: any;

const About: React.FC = () => {
  const openCalendlyPopup = () => {
    Calendly.showPopupWidget('https://calendly.com/insightful-development/30min');
    return false;
  };

  return (
    <section id="about" className="about">
      <div className="about-container">
        <div className="about-image-container">
          <img src={meImage} alt="Laurent Bauchet" className="about-image" />
          <div className="about-logos">
            <a target="_blank" rel="noreferrer" href="https://www.emccfrance.org/utilisateur/laurent-bauchet/"><img src={emccLogo} alt="EMCC France" className="about-logo" /></a>
            <a target="_blank" rel="noreferrer" href="https://www.medoucine.com/consultation/limay/laurent-bauchet/13875?utm_source=bouton-rdv&utm_medium=pack-com&utm_campaign=laurent-bauchet"><img src={medoucineLogo} alt="Médoucine" className="about-logo" /></a>
          </div>
        </div>
        <div className="about-text">
          <h1>Qui je suis?</h1>
          <p>Je suis Laurent Bauchet, coach professionnel certifié, passionné par l'épanouissement humain et la réalisation du potentiel individuel. Ma vocation est d'accompagner chacun et chacune sur le chemin de l'épanouissement et de la réussite personnelle et professionnelle. Avec plus de quinze ans d'expérience dans le secteur de l'IT (Technologies de l’Information) et une pratique dédiée des arts martiaux chinois, j'apporte une perspective unique au coaching, fusionnant la rigueur technologique avec l'art subtil de l'équilibre intérieur.</p>
          <p>Ma pratique de coaching s'enrichit d'une diversité de méthodologies éprouvées telles que la PNL, l'approche systémique, et l'Analyse Transactionnelle. Parallèlement, je puise dans la richesse de mes plus de 25 années de pratique des arts martiaux traditionnels chinois (Kung-fu, Tai Chi Chuan, Qi Gong) pour vous apporter une dimension supplémentaire d'équilibre, de maîtrise et de connaissance de soi. Actuellement en formation en sophrologie caycédienne, je suis en constante évolution pour enrichir mon accompagnement. Cette démarche de formation continue me permet de vous offrir un coaching véritablement holistique, me permettant de vous accompagner au mieux sur l’ensemble des aspects de votre développement personnel et/ou professionnel.</p>
          <p>Affilié à l'EMCC et membre du réseau Médoucine, je m'engage à respecter une déontologie rigoureuse. Ces affiliations sont le reflet de mon engagement envers une pratique éthique et de qualité, garantissant à mes clients un espace de travail sécurisé et confidentiel.</p>
          <p>Dans ma pratique, j'offre un accueil empathique et bienveillant. Mon écoute active et ma capacité à créer un lien authentique permettent d'aborder chaque séance avec ouverture et respect. Que vous soyez un professionnel de la technologie cherchant à affiner vos compétences de leadership, que vous souhaitiez un soutien pour la mise en œuvre d’un projet ou pour atteindre un objectif défini ou que vous aspiriez simplement à  à un changement de vie significatif, je suis là pour vous accompagner.</p>
          <p>Chaque parcours est unique, et je me réjouis de pouvoir contribuer à votre cheminement vers une vie épanouie, où vos rêves et aspirations ne sont plus de lointaines étoiles, mais des réalités tangibles.</p>
          <p>Bienvenue dans un espace où votre développement personnel et professionnel prend son véritable envol – bienvenue chez Insightful Development, votre partenaire de coaching pour une transformation durable.</p>
        </div>
      </div>
      <button onClick={openCalendlyPopup}>Prenez Rendez-Vous</button>
    </section>
  );
};

export default About;
