import React from 'react';
import '../styles/MainBanner.css';

declare const Calendly: any;

const MainBanner: React.FC = () => {
  const openCalendlyPopup = () => {
    Calendly.showPopupWidget('https://calendly.com/insightful-development/30min');
    return false;
  };

  return (
    <section className="main-banner">
      <div className="banner-content">
        <h1>Développez Votre Potentiel</h1>
        <p>Votre voyage vers la réussite et l'épanouissement commence ici avec Insightful Development.</p>
        <button onClick={openCalendlyPopup}>Commencez Maintenant</button>
      </div>
    </section>
  );
};

export default MainBanner;
