import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import '../styles/Header.css';

const Header: React.FC = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);

  const toggleNav = (e: React.MouseEvent) => {
    e.stopPropagation(); // Empêche le clic de se propager au document
    setIsNavVisible(!isNavVisible);
  };

  // Ferme le menu lorsque l'utilisateur clique en dehors du menu
  useEffect(() => {
    // Cette fonction sera appelée à chaque clic sur le document
    const handleOutsideClick = (event: MouseEvent) => {
      // Cast event.target to an Element to satisfy TypeScript's strict null checks
      const target = event.target as Element;

      // Vérifiez si le clic n'est pas sur le bouton nav-toggle
      if (!target.closest('.nav-toggle')) {
        setIsNavVisible(false);
      }
    };

    // Si le menu est visible, ajoutez l'écouteur d'événements
    if (isNavVisible) {
      document.addEventListener('click', handleOutsideClick);
    }

    // Retirez l'écouteur d'événements lorsque le menu n'est pas visible ou lorsque le composant est démonté
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isNavVisible]); // Exécutez useEffect seulement lorsque isNavVisible change

  return (
    <>
      {/* Bandeau "Site en Construction" */}
      {/* <div className="construction-banner">
        Site en Construction
      </div> */}
      <header>
        <div className="logo-container">
          <Link to="/">
            <img src={logo} alt="Insightful Development" className="logo" />
            <span className="logo-title">Insightful Development</span>
          </Link>
        </div>
        <button className="nav-toggle" onClick={toggleNav} aria-label="toggle navigation">
          <FontAwesomeIcon icon={faBars} /> {/* Icône du menu hamburger */}
        </button>
        <nav className={isNavVisible ? "nav-visible" : ""}>
          <ul>
            <li><Link to="/" state={{ scrollTo: 'about' }}>Qui je suis?</Link></li>
            <li><Link to="/" state={{ scrollTo: 'coaching-definition' }}>Mon coaching</Link></li>
            <li><Link to="/deontologie">Ma déontologie</Link></li>
            <li><Link to="/coaching">Le coaching</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li><Link to="/" state={{ scrollTo: 'testimonials' }}>Témoignages</Link></li>
            {/* <li><a href="#contact">Contact</a></li> */}
          </ul>
        </nav>
      </header>
    </>
  );
};

export default Header;
