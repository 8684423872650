import React from 'react';
import '../styles/Deontology.css';

declare const Calendly: any;

const Deontology = () => {
  const openCalendlyPopup = () => {
    Calendly.showPopupWidget('https://calendly.com/insightful-development/30min');
    return false;
  };

  return (
    <section className="deontology">
      <div className="deontology-container">
        <div className="deontology-text">
          <h1>Ma déontologie</h1>
          <p>
            <ol>
              <li><b>Excellence Professionnelle:</b> Je m'engage à promouvoir et appliquer l'excellence des pratiques de coaching, en respectant les standards de qualité les plus élevés, conformément aux exigences de l'EMCC et aux législations européennes.</li>
              <li><b>Intégrité et Transparence:</b> Dans toutes mes interactions professionnelles, je m'engage à communiquer de manière transparente et honnête, en fournissant des informations précises sur mes qualifications, mon expérience et ma méthodologie.</li>
              <li><b>Confidentialité Assurée:</b> Je garantie la plus stricte confidentialité dans toutes mes relations de travail, en veillant à la protection des données personnelles et au respect de la vie privée de mes clients, conformément aux lois en vigueur.</li>
              <li><b>Bien-être du Client:</b> Mon action est guidée par l'intérêt supérieur du client. Je veille à ce que la dynamique de coaching reste centrée sur le bien-être et les objectifs personnels du client, en évitant toute influence externe ou personnelle pouvant interférer avec le processus de coaching.</li>
              <li><b>Relations Professionnelles Claires:</b> Je m'efforce de clarifier les attentes et les objectifs dès le début de toute relation de coaching, en établissant des contrats clairs qui respectent les besoins et l'autonomie de mes clients.</li>
              <li><b>Respect de la Diversité:</b> Je suis engagé dans le respect de la diversité et l'égalité, en m'assurant de ne faire preuve d'aucune discrimination et en cultivant une sensibilité accrue aux différents contextes culturels et individuels de mes clients.</li>
              <li><b>Prévention des Conflits d'Intérêts:</b> Je suis vigilant à identifier et à résoudre rapidement tout conflit d'intérêts qui pourrait survenir, en mettant toujours les intérêts de mes clients avant les miens.</li>
              <li><b>Développement Professionnel Continu:</b> Je m'engage dans un processus continu de formation et de développement pour affiner et élargir mes compétences de coach, contribuant ainsi à l'essor de la profession.</li>
              <li><b>Supervision Éthique:</b> Je suis moi-même, comme l'exige une pratique de coaching de qualité, soutenu par un coach superviseur expérimentés que je peux contacter au besoin et au minimum 6 fois par an pour maintenir l'excellence de ma pratique, en discutant régulièrement de tout enjeu éthique ou professionnel.</li>
              <li><b>Mesure de la Qualité:</b> Je suis déterminé à évaluer constamment la qualité de ma pratique, en sollicitant des retours de la part de mes clients, de mon superviseur et d'autres professionnels, afin de garantir une prestation toujours améliorée.</li>
            </ol>
          </p>
          <p>Chacun de ces points reflètent combien il est important pour moi de pratiquer un coaching qui non seulement respecte des normes éthiques élevées, mais qui cherche également à faire une différence positive dans la vie des clients.</p>
        </div>
      </div>
      <button onClick={openCalendlyPopup}>Démarrez Maintenant</button>
    </section>
  );
};

export default Deontology;
