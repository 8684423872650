import React from 'react';
import '../styles/Process.css';

declare const Calendly: any;

const CoachingDefinition = () => {
  const openCalendlyPopup = () => {
    Calendly.showPopupWidget('https://calendly.com/insightful-development/30min');
    return false;
  };

  return (
    <section className="process">
      <div className="process-container">
        <div className="process-text">
          <p>Le coaching que je propose est une aventure intérieure orientée vers l'action et le changement tangible. Il s'agit d'un partenariat réfléchi et progressif qui favorise l'auto-réflexion, la découverte de soi et la transformation personnelle et professionnelle. Mon coaching n'est pas une solution express ou un remède miracle; c'est une exploration patiente et approfondie qui valorise le processus autant que les résultats.</p>

          <h1>Définition du Coaching</h1>
          <p>Le coaching est une profession guidée par des principes de confiance, de respect et de confidentialité. En tant que coach, je m'engage à vous fournir un espace où vos pensées et vos sentiments peuvent être exprimés librement, sans jugement. Je m'emploie à écouter activement, à poser des questions qui vous amèneront à l’introspection et à vous défier avec compassion pour vous aider à clarifier et à atteindre vos objectifs. Mon rôle est de révéler votre sagesse intérieure, de vous aider à surmonter les obstacles et de célébrer avec vous chaque succès.</p>

          <h1>Déroulement d'une Séance Type de Coaching</h1>
          <p>
            <ol>
                <li><b>Accueil et mise en place de l'espace de coaching:</b> Un moment pour vous centrer sur vous-même et établir la confidentialité et le confort nécessaire à une session productive.</li>
                <li><b>Revue des objectifs et des progrès:</b> Nous évaluons où vous en êtes par rapport à vos objectifs et mettrons en valeur les succès déjà obtenus.</li>
                <li><b>Travail en profondeur:</b> À l'aide de techniques de coaching adaptées, nous explorons les défis actuels et les opportunités d'apprentissage.</li>
                <li><b>Plan d'action:</b> Développement de stratégies concrètes et personnalisées pour vous permettre d'avancer vers vos objectifs entre les séances.</li>
                <li><b>Clôture:</b> Nous résumons les points clés de la session, clarifions les étapes suivantes et renouvelons notre engagement mutuel dans le processus de coaching.</li>
            </ol>
          </p>

          <p>Le coaching est un voyage d'évolution continue. Chaque session est un pas de plus vers la meilleure version de vous-même, équipée pour affronter les défis avec assurance et agir avec une intention claire et déterminée.</p>
        </div>
      </div>
      <button onClick={openCalendlyPopup}>Commencez Maintenant</button>
    </section>
  );
};

export default CoachingDefinition;
