import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import '../styles/Footer.css';

const Footer = () => {
  return (
    <footer className="site-footer">
      <div className="footer-content">
        <div className="footer-section">
          <h4>Contact</h4>
          <p><a href="mailto:bauchet.laurent@gmail.com">Email: bauchet.laurent@gmail.com</a></p>
          <p><a href="tel:+33652056267">Tél: +33(0) 6 52 05 62 67</a></p>
        </div>
        <div className="footer-section">
          <h4>Liens Rapides</h4>
          <ul>
            <li><Link to="/" state={{ scrollTo: 'about' }}>Qui je suis?</Link></li>
            <li><Link to="/" state={{ scrollTo: 'coaching-definition' }}>Mon coaching</Link></li>
            <li><Link to="/deontologie">Ma déontologie</Link></li>
            <li><Link to="/coaching">Le coaching</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li><Link to="/" state={{ scrollTo: 'testimonials' }}>Témoignages</Link></li>
            {/* <li><a href="#contact">Contact</a></li> */}
          </ul>
        </div>
        <div className="footer-section">
          <h4>Réseaux Sociaux</h4>
          <p>Suivez-nous sur :</p>
          <a target="_blank" rel="noreferrer" href="https://www.facebook.com/profile.php?id=61555055679546"><FontAwesomeIcon icon={faFacebook} /></a>
          <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/laurent-bauchet-836b9b7/"><FontAwesomeIcon icon={faLinkedin} /></a>
          <a target="_blank" rel="noreferrer" href="https://www.instagram.com/indevlb/"><FontAwesomeIcon icon={faInstagram} /></a>
          <a target="_blank" rel="noreferrer" href="https://www.medoucine.com/consultation/limay/laurent-bauchet/13875?utm_source=bouton-rdv&utm_medium=pack-com&utm_campaign=laurent-bauchet"><img height="14" src="https://cdn2.medoucine.com/pack-communication/label-medoucine.png?v=1" alt="Label Médoucine"/></a>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2023 Insightful Development. Tous droits réservés.</p>
      </div>
    </footer>
  );
}

export default Footer;
