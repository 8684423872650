import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import MainBanner from './components/MainBanner';
import About from './components/About';
import CoachingDefinition from './components/CoachingDefinition';
import Testimonials from './components/Testimonials';
import Deontology from './components/Deontology';
import Services from './components/Services';
import Process from './components/Process';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import './App.css';

const App: React.FC = () => {
  return (
    <Router>
      <ScrollToTop /> {/* Ajoutez le composant ici */}
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <MainBanner />
              <About />
              <CoachingDefinition />
              <Testimonials />
            </>
          } />
          <Route path="/deontologie" element={<Deontology />} />
          <Route path="/services" element={<Services />} />
          <Route path="/coaching" element={<Process />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
