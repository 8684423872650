import React, { useState, useEffect } from 'react';
import '../styles/Testimonials.css'; // Assurez-vous que le chemin est correct

const testimonials = [
  {
    id: 1,
    text: `Depuis mes séances avec Mr Bauchet , j'ai enfin pris confiance en moi et en mes compétences. Il m' a permis de comprendre ce que je voulais changer pour être plus heureuse.
    J'ai gagné en assurance et en détermination : de ce fait, j'ai réussi à mettre tout en oeuvre pour concrétiser les changements nécessaires dans ma vie personnelle et professionnelle . Je suis moins angoissée , plus apaisée et sereine lors de mes prises de décision.
    Je recommande Mr Bauchet , qui, par son écoute, ses ressources, son soutien permet de croire en nos objectifs et de s'en approcher, au plus près de nos possibilités.`,
    sign: "Céline B."
  },
  {
    id: 2,
    text: "J'ai beaucoup apprécié l'ambiance sereine et respectueuse de mes séances de coaching avec Laurent. Il m'a aidé à dépasser certaines de mes limites et questionner le sens de la démarche dans laquelle il m'a accompagné. Il m'a aiguillonné vers mes objectifs tout en respectant mon rythme de progression. Je recommande Laurent pour son professionnalisme et l'équilibre de sa méthode de coaching.",
    sign: "Gabriel F."
  },
  {
    id: 3,
    text: "Le coaching avec Laurent fût une belle découverte. C’était un réel moment d’échanges, un exutoire, une découverte de moi-même et de mes besoins. Je remercie Laurent pour son professionnalisme, sa gentillesse et son écoute. Son accompagnement m'a fait le plus grand bien et m'a permis d’atteindre mon objectif sur le long terme. Je ne peux que le recommander.",
    sign: "Mirjana N."
  },
  {
    id: 4,
    text: "De janvier à avril 2023, j'ai pu réaliser des séances de coaching auprès de Laurent. Ces séances ont été très riches en découvertes sur moi, mes attentes, mes aspirations et blocages. Laurent a fait preuve de bienveillance, d'accompagnement et de professionnalisme et à apporter une réelle approche pour mon besoin. Il m'a apporté les outils pour poursuivre au mieux mon développement personnel. Pour toutes ces raisons je recommande ce praticien.",
    sign: "Stef"
  },
  {
    id: 5,
    text: `J'ai eu le plaisir de connaître Laurent au cours de ma formation en coaching, et il fait partie de ces personnes qui laissent une trace dans notre parcours de vie.

    Laurent est un coach très à l'écoute, avec qui on se sent immédiatement à l'aise.
    Il connaît l'art du questionnement, qui permet de penser plus loin, en dehors de nos schémas habituels, et de trouver des solutions nouvelles pour avancer malgré les obstacles.

    Sa personnalité souriante et dynamique transmet une belle énergie lors des séances de coaching. Curieux et toujours en recherche de nouvelles méthodes pour s'adapter aux besoins des personnes qu'il accompagne, il sait faire bénéficier de son expérience, qu'elle soit professionnelle ou simplement humaine.

    Si vous êtes en recherche de sérénité et de confiance, je vous recommande ce coach sans réserve.`,
    sign: "Jean-Philippe Teissier (Coach)"
  },
  {
    id: 6,
    text: "J'ai eu la chance de collaborer avec Laurent sur un coaching d'équipe en entreprise. Je suis formé en gestion de stress notamment au travail et Laurent est spécialisé dans le coaching professionnel, avec de multiples cordes à son arc. Excellent coach et collaborateur, je ne pouvais pas mieux tomber pour accompagner une équipe connaissant un surcroît d'activité et en tension. Ce fut un succès. Je le remercie d'avoir accepté de collaborer et j'espère pérenniser cela sur de futurs coaching en entreprise.",
    sign: "Aurélien Collinet (Coach)"
  },
  {
    id: 7,
    text: `Manager dans un grand groupe bancaire, j'ai la chance de travailler avec une collaboratrice à fort potentiel d'évolution. Je savais qu'en l'orientant vers Laurent, dont j'avais pu constater le professionnalisme, la vivacité intellectuelle et une bienveillance sans complaisance en matière de coaching, je lui offrais d'autres leviers pour continuer à la faire grandir. L'accompagnement de Laurent lui a ainsi permis de franchir plusieurs paliers de développement, qu'elle aurait sans doute réalisés par elle-même mais sur plusieurs années. Elle a notamment gagné en confiance en elle, en assertivité ou encore en prise de hauteur sur ses décisions au sein de l'entreprise, mais aussi dans la sphère familiale m'a-t-elle dit. J'ai eu plaisir à voir son  "plafond de verre limitant" éclater, contribuant à son épanouissement et son équilibre professionnel tout autant que personnel.
    Nul doute que la rencontre avec Laurent restera un moment charnière dans sa vie sur lequel elle continue de s'appuyer.
    Je recommande !`,
    sign: "Jean-François Desseaux (Responsable Diversité et Santé au Travail chez CGI FINANCE)"
  },
  // {
  //     id: 7,
  //     text: "",
  //     sign: "Vanessa C."
  // },
  {
    id: 8,
    text: `Je connais Laurent depuis de nombreuses années, rencontré à travers notre pratique commune des arts martiaux chinois. En plus d'être un excellent pratiquant dans ce domaine, il excelle, à mon humble avis, également en tant que coach.

    Laurent possède une compréhension profonde des aspects physiques, mentaux et spirituels du bien-être. Grâce à son approche holistique, il est un guide précieux pour les personnes qu'il accompagne.

    Ses qualités de coach sont amplifiées par sa patience, son écoute active et sa capacité à inspirer le changement positif. Humble, travailleur et honnête, Laurent crée un environnement de soutien où chacun se sent entendu et encouragé. Ses conseils pratiques et sa vision claire sont des qualités essentielles.

    Je recommande chaleureusement Laurent pour son expertise, son approche intégrative et sa capacité à inspirer le progrès.`,
    sign: "Anthony Gareggi (Praticien en médecine chinoise)"
  },
];

const Testimonials = () => {
  const [current, setCurrent] = useState(0);
  const length = testimonials.length;

  const nextTestimonial = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevTestimonial = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent(current => (current === length - 1 ? 0 : current + 1));
    }, 30000); // Change tous les 30 secondes

    return () => clearInterval(interval);
  }, [length]);

  return (
    <div className="testimonials" id="testimonials">
      <h1>Témoignages</h1>
      <div className="testimonial" key={testimonials[current].id}>
        <p>{testimonials[current].text}</p>
        <p>{testimonials[current].sign}</p>
      </div>
      <div className="indicators">
        {testimonials.map((_, index) => (
          <span
            key={index}
            className={`indicator ${index === current ? 'active' : ''}`}
            onClick={() => setCurrent(index)}
          ></span>
        ))}
      </div>
      <button onClick={prevTestimonial}>&#10094;</button>
      <button onClick={nextTestimonial}>&#10095;</button>
    </div>
  );
}

export default Testimonials;
