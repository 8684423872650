import React from 'react';
import coachingIllustration from '../assets/coaching-vision2.png';
import '../styles/CoachingDefinition.css';

declare const Calendly: any;

const CoachingDefinition = () => {
  const openCalendlyPopup = () => {
    Calendly.showPopupWidget('https://calendly.com/insightful-development/30min');
    return false;
  };

  return (
    <section id="coaching-definition" className="coaching-definition">
      <div className="coaching-definition-container">
        <div className="coaching-definition-text">
          <h1>Mon coaching</h1>
          <div className="coaching-illustration">
            <img src={coachingIllustration} alt="Coaching Illustration" />
          </div>
          <p>Le coaching, dans ma pratique en distanciel et en présentiel, est un voyage de transformation personnelle et/ou professionnelle. C'est un processus collaboratif qui offre un espace de réflexion et d'action à ceux qui cherchent à améliorer leur qualité de vie, à réaliser leurs aspirations ou à surmonter des obstacles. Je crois fermement que chaque individu possède un potentiel immense et parfois inexploité, et mon rôle en tant que coach est de vous aider à accéder à ce potentiel, à le cultiver et à le mettre en œuvre.</p>
          <p>À travers des séances personnalisées, je me consacre à l'écoute active de vos besoins et objectifs. J'utilise une gamme d'outils pratiques et des techniques éprouvées, tirées de disciplines telles que la Programmation Neuro-Linguistique (PNL), l'Analyse Transactionnelle et la systémique, pour vous accompagner vers une meilleure connaissance de vous-même et des actions concrètes. Mon engagement envers une déontologie rigoureuse garantit un cadre sécurisant et confidentiel, où vous pouvez explorer vos pensées et émotions librement.</p>
          <p>En tant que coach certifié, affilié à l'EMCC et faisant partie du réseau Médoucine, je m'engage à maintenir les standards élevés de la profession, en offrant une pratique de qualité, éthique et respectueuse de chacun et chacune. Ces affiliations ne sont pas de simples logos sur ma page, mais la marque de mon engagement envers la rigueur et la confiance.</p>
          <p>L'empathie, le respect et la capacité d'établir un lien authentique sont au cœur de ma méthode. Je comprends les défis uniques auxquels sont confrontés les professionnels de la technologie et tous ceux en quête de développement personnel. Après quelques années à éprouver moi-même des difficultés à équilibrer ma vie professionnelle et ma vie personnelle, une évolution professionnelle m'a menée à mieux envisager cet équilibre qui constituent la vie de chacun et chacune; je travaille maintenant chaque jour à maintenir cet équilibre qui me permet de m'épanouir plus sereinement dans mon métier, dans mes passions et dans ma vie de famille.</p>
          <p>Le coaching pour moi, c'est plus qu'une profession; c'est une mission. C'est l'opportunité d'accompagner chaque client dans l'écriture d'un nouveau chapitre de sa vie, avec plus de confiance, de clarté et de sens.</p>
        </div>
      </div>
      <button onClick={openCalendlyPopup}>Parlons De Vous</button>
    </section>
  );
};

export default CoachingDefinition;
