// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.site-footer {
  background-color: #026873;
  color: white;
  text-align: center;
  padding: 20px 0;
  font-family: Arial, sans-serif;
}

/* Ajouts pour les icônes de réseaux sociaux */
.site-footer a {
  color: white;
  margin-right: 10px;
  text-decoration: none;
}

.site-footer a:hover {
  color: #ddd;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.footer-section h4 {
  margin-bottom: 15px;
}

.footer-section ul {
  text-align: left;
}

.footer-section p, .footer-section a {
  color: white;
  line-height: 1.6;
}

.footer-section a {
  text-decoration: none;
}

.footer-bottom {
  font-size: 0.8em;
  margin-top: 20px;
}

/* Media Queries for smaller screens */
@media screen and (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    text-align: left;
  }

  .footer-section {
    margin-bottom: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Footer.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,8BAA8B;AAChC;;AAEA,8CAA8C;AAC9C;EACE,YAAY;EACZ,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA,sCAAsC;AACtC;EACE;IACE,sBAAsB;IACtB,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".site-footer {\n  background-color: #026873;\n  color: white;\n  text-align: center;\n  padding: 20px 0;\n  font-family: Arial, sans-serif;\n}\n\n/* Ajouts pour les icônes de réseaux sociaux */\n.site-footer a {\n  color: white;\n  margin-right: 10px;\n  text-decoration: none;\n}\n\n.site-footer a:hover {\n  color: #ddd;\n}\n\n.footer-content {\n  display: flex;\n  justify-content: space-around;\n  margin-bottom: 20px;\n}\n\n.footer-section h4 {\n  margin-bottom: 15px;\n}\n\n.footer-section ul {\n  text-align: left;\n}\n\n.footer-section p, .footer-section a {\n  color: white;\n  line-height: 1.6;\n}\n\n.footer-section a {\n  text-decoration: none;\n}\n\n.footer-bottom {\n  font-size: 0.8em;\n  margin-top: 20px;\n}\n\n/* Media Queries for smaller screens */\n@media screen and (max-width: 768px) {\n  .footer-content {\n    flex-direction: column;\n    text-align: left;\n  }\n\n  .footer-section {\n    margin-bottom: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
