import React from 'react';
import '../styles/Services.css';

declare const Calendly: any;

const Services = () => {
    const openCalendlyPopup = () => {
      Calendly.showPopupWidget('https://calendly.com/insightful-development/30min');
      return false;
    };

    return (
      <section className="target_services">
        <div className="target-container">
          <div className="target-text">
            <h1>À qui s'adressent mes services de coaching ?</h1>
            <p>Mes services de coaching en distanciel et en présentiel sont destinés à ceux qui sont en quête d'amélioration et de changement positif dans leur vie professionnelle et personnelle. Que vous soyez un professionnel désireux de se réaliser, un leader en devenir, ou toute personne souhaitant évoluer, s'épanouir, apprendre à se connaître ou encore si vous avez juste besoin d'un coup de pouce pour atteindre un objectif professionnel, personnel ou sportif; je suis là pour vous accompagner.</p>
            <p>Ensemble, explorons et surmontons les défis suivants:</p>
            <p>
              <ol>
                  <li><b>Évolution professionnelle:</b> Construisez une carrière gratifiante, engagez-vous dans une reconversion significative et développez votre leadership.</li>
                  <li><b>Intelligence émotionnelle:</b> Apprenez à identifier, comprendre et gérer vos émotions pour une conscience de soi accrue.</li>
                  <li><b>Résilience et Communication:</b> Renforcez votre capacité à gérer le stress, à maîtriser les conflits et à interagir efficacement avec autrui.</li>
                  <li><b>Développement Personnel:</b> Améliorez votre gestion du temps, stimulez votre créativité et cultivez des relations sociales enrichissantes.</li>
                  <li><b>Confiance et Potentiel:</b> Dépassez les blocages, boostez votre motivation et embrassez votre potentiel.</li>
                  <li><b>Gestion du stress:</b> Ne laissez plus votre stress vous empêcher d'atteindre vos objectifs et accédez à la sérénité que vous recherchez.</li>
                  <li><b>Préparation mentale:</b> Quelque soit les défis (examens, concours, compétition) que vous aurez à relever, affrontez les avec confiance et sérénité.</li>
                  <li><b>Prise de parole en public:</b> Devenez l'orateur que vous avez toujours eu envie d'être et communiquer efficacement avec votre public.</li>
                  <li><b>Estime de soi et Confiance en soi:</b> Reprenez confiance en vous et atteignez vos objectifs, soyez heureux(ses) et épanoui(e).</li>
              </ol>
            </p>
            <p>Chaque session est un pas vers une meilleure compréhension de soi et une plus grande maîtrise des situations de vie. Si vous aspirez à une vie plus équilibrée, à une confiance renouvelée, ou à une adaptabilité accrue face aux changements, mon coaching est l'outil qu'il vous faut. Nous travaillerons ensemble pour que vous puissiez prendre de la hauteur, apprivoiser vos émotions et naviguer avec agilité à travers les défis professionnels et personnels.</p>
          </div>
        </div>
        <button onClick={openCalendlyPopup}>Prenez Rendez-Vous</button>
        <div className="services">
          <div className="service">
            <h2>Service Flash Coaching</h2>
            <h3>Un Moment pour Soi, Un Pas vers le Changement</h3>
            <p><b>Durée:</b> 1h30</p>
            <p><b>Tarif:</b> 90€</p>
            <p><b>Description:</b></p>
            <p>Dans un monde en constante évolution, prendre du temps pour soi n'est pas un luxe, mais une nécessité. Le service Flash Coaching de Laurent Bauchet est conçu pour ceux qui cherchent un soutien immédiat et efficace face aux défis de la vie quotidienne. Que vous soyez confronté à un stress professionnel, à des incertitudes personnelles, ou simplement à la recherche d'un éclaircissement, cette séance de 1h30 est votre fenêtre vers le changement.</p>
            <h4>Ce que Vous Pouvez Attendre:</h4>
            <ul>
              <li>Écoute Active et Personnalisée: Votre séance est entièrement consacrée à vos besoins et objectifs.</li>
              <li>Outils Pratiques: Vous repartirez avec des stratégies concrètes pour gérer le stress, améliorer la confiance en soi, et dépasser les croyances limitantes.</li>
              <li>Approche Holistique: Intégrant des techniques de PNL, de systémique, d'Analyse Transactionnelle, et des principes de la médecine traditionnelle chinoise et des arts martiaux.</li>
              <li>Plan d'Action: Un plan personnalisé pour appliquer immédiatement les insights et les stratégies abordés.</li>
            </ul>
            <h4>Pour Qui ?</h4>
            <p className="service-traget">Ce service est idéal pour ceux qui:</p>
            <ul>
              <li>Cherchent des réponses rapides et des stratégies efficaces.</li>
              <li>Souhaitent découvrir le coaching et son impact potentiel sur leur vie.</li>
              <li>Ont besoin d'une session de coaching mais sont limités par le temps.</li>
            </ul>
            <button onClick={openCalendlyPopup}>Réserver Votre Séance Maintenant</button>
          </div>
          <div className="service">
            <h2>Service de Renforcement de la Confiance en Soi</h2>
            <h3>Ouvrez la Porte à Votre Puissance Intérieure</h3>
            <p><b>Durée:</b> 5 séances</p>
            <p><b>Tarif:</b> 430€</p>
            <p><b>Description:</b></p>
            <p>La confiance en soi est le socle sur lequel repose la réalisation de nos ambitions et de nos relations. Le service de renforcement de la confiance en soi offert par Laurent Bauchet est une invitation à redécouvrir et à amplifier votre force intérieure. À travers des sessions personnalisées, embarquez pour un voyage transformationnel qui vous révèlera comment vous tenir avec assurance au centre de votre vie personnelle et professionnelle.</p>
            <h4>Ce que Vous Pouvez Attendre:</h4>
            <ul>
              <li>Évaluation Personnalisée: Un diagnostic de votre niveau de confiance actuel pour cibler précisément les zones de croissance.</li>
              <li>Techniques Éprouvées: L'utilisation de méthodes validées telles que la PNL, l'Analyse Transactionnelle et la systémique pour reconstruire les fondements de votre estime personnelle.</li>
              <li>Exercices Pratiques: Des exercices concrets pour renforcer au quotidien votre assertivité et votre présence.</li>
              <li>Soutien Continu: Un suivi régulier pour assurer le développement durable de votre confiance en vous.</li>
            </ul>
            <h4>Pour Qui ?</h4>
            <p className="service-traget">Ce service est idéal pour ceux qui:</p>
            <ul>
              <li>Aspirent à se libérer des doutes et des incertitudes.</li>
              <li>Veulent affirmer leur place dans leur environnement professionnel ou social.</li>
              <li>Sont prêts à investir en eux-mêmes pour réaliser leur potentiel.</li>
            </ul>
            <button onClick={openCalendlyPopup}>Reprenez Confiance Maintenant</button>
          </div>
          <div className="service">
            <h2>Maîtrise Emotionnelle</h2>
            <h3>Naviguez l'Océan des Émotions avec Assurance</h3>
            <p><b>Durée:</b> 4 séances</p>
            <p><b>Tarif:</b> 350€</p>
            <p><b>Description:</b></p>
            <p>Les émotions colorent notre expérience de la vie, mais lorsqu'elles prennent le dessus, il est crucial de savoir naviguer leurs flots. Le service de Maîtrise Emotionnelle proposé par Laurent Bauchet vous outille pour reconnaître, comprendre et gérer vos émotions. Grâce à une approche empathique et structurée, transformez vos émotions en alliées pour une vie plus équilibrée et harmonieuse.</p>
            <h4>Ce que Vous Pouvez Attendre:</h4>
            <ul>
              <li>Compréhension Approfondie: Un accompagnement pour mieux comprendre l'origine et l'impact de vos émotions.</li>
              <li>Stratégies Personnalisées: Développement de stratégies sur mesure pour réguler vos émotions dans diverses situations.</li>
              <li>Techniques de Respiration et de Relaxation: Apprentissage de techniques pour calmer rapidement le stress et l'anxiété.</li>
              <li>Exercices de Pleine Conscience: Pratiques pour vous ancrer dans le moment présent et réduire la réactivité émotionnelle.</li>
            </ul>
            <h4>Pour Qui ?</h4>
            <p className="service-traget">Ce service est idéal pour ceux qui:</p>
            <ul>
              <li>Souhaitent développer une meilleure intelligence émotionnelle.</li>
              <li>Veulent contrôler efficacement leur stress et leurs réactions dans des situations délicates.</li>
              <li>Cherchent à établir des relations plus saines et plus satisfaisantes.</li>
            </ul>
            <button onClick={openCalendlyPopup}>Commencez Maintenant</button>
          </div>
          <div className="service">
            <h2>Évolution de Carrière et Reconversion Professionnelle</h2>
            <h3>Embrassez Votre Avenir Professionnel avec Assurance</h3>
            <p><b>Durée:</b> 6 séances</p>
            <p><b>Tarif:</b> 510€</p>
            <p><b>Description:</b></p>
            <p>L'évolution de carrière et la reconversion professionnelle sont des étapes cruciales qui demandent réflexion et préparation. En tant que coach professionnel certifié, Laurent Bauchet vous propose un accompagnement ciblé pour vous préparer à ces transitions. Grâce à des techniques de coaching éprouvées, je vous aide à clarifier vos aspirations et à mettre en place les actions nécessaires pour une transition harmonieuse et alignée avec vos valeurs et vos compétences.</p>
            <h4>Ce que Vous Pouvez Attendre:</h4>
            <ul>
              <li>Clarification des Objectifs: Aide à définir vos objectifs de carrière pour une orientation claire et motivante.</li>
              <li>Gestion du Changement: Support pour gérer le changement et les émotions associées, renforçant la confiance en vos décisions.</li>
              <li>Développement Personnel: Accompagnement dans le développement de compétences transversales telles que la communication et la gestion du stress.</li>
              <li>Plan d'Action Personnalisé: Élaboration d'un plan d'action personnalisé pour faciliter votre transition et atteindre vos objectifs professionnels.</li>
            </ul>
            <h4>Pour Qui ?</h4>
            <p className="service-traget">Ce service est idéal pour ceux qui:</p>
            <ul>
              <li>Veulent donner un nouveau souffle à leur vie professionnelle.</li>
              <li>Sont à la recherche d'un alignement plus profond entre leurs valeurs personnelles et leur travail.</li>
              <li>Désirent préparer et planifier une reconversion professionnelle avec un cadre structuré.</li>
            </ul>
            <button onClick={openCalendlyPopup}>Commencez Maintenant</button>
          </div>
          <div className="service">
            <h2>Coaching Personnalisé</h2>
            <h3>Votre Parcours Sur Mesure vers l'Accomplissement</h3>
            <p><b>Durée:</b> à déterminé selon les besoins (12 séances maxi)</p>
            <p><b>Tarif:</b> selon la durée </p>
            <p><b>Description:</b></p>
            <p>Le coaching personnalisé est une approche sur mesure, adaptée à vos aspirations uniques et à vos défis spécifiques. Que vous soyez en quête de croissance personnelle, de développement professionnel, ou d'une meilleure gestion émotionnelle, je suis là pour vous guider avec empathie et expertise. Grâce à une écoute attentive et à des techniques de coaching éprouvées, nous travaillons ensemble pour définir et atteindre vos objectifs les plus significatifs.</p>
            <h4>Ce que Vous Pouvez Attendre:</h4>
            <ul>
              <li>Écoute Active et Personnalisée: Chaque séance est centrée sur vous - vos besoins, vos défis, vos objectifs.</li>
              <li>Outils et Stratégies Sur Mesure: Des techniques adaptées à votre situation, pour des résultats concrets et durables.</li>
              <li>Accompagnement Continu: Un soutien régulier pour maintenir le cap et ajuster les stratégies au besoin.</li>
              <li>Développement de Compétences: Renforcement de vos compétences en communication, gestion du stress, prise de décision, et bien plus.</li>
            </ul>
            <h4>Pour Qui ?</h4>
            <p className="service-traget">Ce service est idéal pour ceux qui:</p>
            <ul>
              <li>Cherchent un accompagnement adapté à leur situation unique.</li>
              <li>Aspirent à un développement personnel ou professionnel spécifique.</li>
              <li>Souhaitent aborder des aspects variés de leur vie avec un coach dédié.</li>
            </ul>
            <button onClick={openCalendlyPopup}>Parlons en Maintenant</button>
          </div>
          <div className="service">
            <h2>Coaching en Entreprise</h2>
            <h3>Cultivez l'Excellence et la Cohésion au Sein de Votre Équipe</h3>
            <p><b>Durée:</b> à déterminé selon les besoins</p>
            <p><b>Tarif:</b> selon la durée et les besoins</p>
            <p><b>Description:</b></p>
            <p>Le coaching en entreprise est conçu pour renforcer les compétences, la motivation et l'engagement des salariés, ainsi que pour améliorer la dynamique et la performance des équipes. En tant que coach professionnel, je propose des interventions personnalisées qui répondent aux besoins spécifiques de votre entreprise, qu'il s'agisse de coaching individuel pour le développement personnel et professionnel, ou de coaching d'équipe pour une meilleure collaboration et efficacité.</p>
            <h4>Ce que Vous Pouvez Attendre du Coaching Individuel:</h4>
            <ul>
              <li>Développement des compétences et leadership personnel</li>
              <li>Gestion du stress et résilience</li>
              <li>Clarification des objectifs de carrière et planification du développement professionnel</li>
            </ul>
            <h4>Ce que Vous Pouvez Attendre du Coaching d'Équipe:</h4>
            <ul>
              <li>Renforcement de la cohésion et de la communication d'équipe</li>
              <li>Gestion des conflits et création d'un environnement de travail collaboratif</li>
              <li>Développement de stratégies d'équipe pour améliorer la performance et l'efficacité</li>
            </ul>
            <h4>Pour Qui ?</h4>
            <p className="service-traget">Ce service est idéal pour ceux qui:</p>
            <ul>
              <li>Entreprises cherchant à investir dans le développement de leurs salariés</li>
              <li>Équipes désireuses d'améliorer leur collaboration et leur efficacité</li>
              <li>Leaders et managers souhaitant renforcer leur capacité à diriger et à inspirer</li>
            </ul>
            <button onClick={openCalendlyPopup}>Parlons en Maintenant</button>
          </div>
        </div>
      </section>
    );
}

export default Services;
